import React, { lazy, Suspense, useState } from "react";
import { Switch, Route, NavLink, withRouter } from "react-router-dom";
import { Layout, Menu } from "antd";

import "./App.css";

const Introduction = lazy(() => import("./sections/intro"));
const Part1 = lazy(() => import("./sections/part-1"));
const Part2 = lazy(() => import("./sections/part-2"));
const Part3 = lazy(() => import("./sections/part-3"));

const App = ({ location }) => {
  let currentKey = location.pathname.split("/")[1].toLowerCase() || "intro";
  const [firstKey, setFirstKey] = useState(currentKey);
  currentKey = currentKey === "embed" ? "intro" : currentKey;

  return (
    <Layout style={{ minHeight: "100%" }}>
      {firstKey !== "embed" && (
        <Layout.Header
          style={{ display: "flex", alignItems: "center", height: 82 }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              height: "100%"
            }}
          >
            <img
              src="https://cdn.teaching.unsw.edu.au/unswbranding/unsw_neg.png"
              alt="UNSW logo"
              style={{
                maxHeight: "100%",
                width: "auto",
                padding: "10px 0",
                marginRight: 25
              }}
            />

            <h1
              style={{
                color: "#FFF",
                margin: 0,
                fontFamily: "Sommet",
                fontSize: 24,
                lineHeight: 1
              }}
            >
              VISN2211 Organisation and Function of the Visual System
            </h1>
          </div>
        </Layout.Header>
      )}

      <Layout.Content style={{ padding: 24, flex: 0 }}>
        <Layout style={{ padding: "24px 0 60px", background: "#fff" }}>
          <Layout.Sider width={250} style={{ background: "#fff" }}>
            <Menu
              mode="inline"
              defaultSelectedKeys={[currentKey]}
              style={{ height: "100%" }}
            >
              <Menu.Item key="intro">
                <NavLink to="/">Introduction</NavLink>
              </Menu.Item>
              <Menu.Item key="part-1">
                <NavLink to="/part-1">Part 1 - Classic receptive field</NavLink>
              </Menu.Item>
              <Menu.Item key="part-2">
                <NavLink to="/part-2">Part 2 - Simple cell</NavLink>
              </Menu.Item>
              <Menu.Item key="part-3">
                <NavLink to="/part-3">
                  Part 3 - Direction selective cell
                </NavLink>
              </Menu.Item>
            </Menu>
          </Layout.Sider>

          <Layout.Content
            style={{
              padding: "0 32px 18px 32px",
              minHeight: 400,
              maxWidth: 900
            }}
          >
            <Switch>
              <Suspense fallback={<div>Loading...</div>}>
                <Route exact path="/" component={Introduction} />
                <Route exact path="/embed" component={Introduction} />
                <Route path="/part-1" component={Part1} />
                <Route path="/part-2" component={Part2} />
                <Route path="/part-3" component={Part3} />
              </Suspense>
            </Switch>
          </Layout.Content>
        </Layout>
      </Layout.Content>

      <Layout.Footer
        style={{
          paddingTop: 0,
          textAlign: "center",
          color: "rgba(97, 97, 97, 0.7)",
          fontWeight: 100,
          fontSize: 12
        }}
      >
        Office of the Pro-Vice Chancellor (Education)
      </Layout.Footer>
    </Layout>
  );
};

export default withRouter(App);
